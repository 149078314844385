<template>
  <div>
    <el-dialog
      :visible="inviteDialog.visible"
      width="468px"
      title="提示"
      destroy-on-close
      @close="handleClose"
    >
      <div class="body-wrapper">
        <p class="invite-msg">
          管理员邀请您成为Live嘉宾上台发言
        </p>
        <p>若您同意成为Live嘉宾，将自动开启麦克风</p>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="handleReject"
        >
          拒绝
        </el-button>
        <el-button
          type="primary"
          size="mini"
          @click="handleAgree"
        >
          同意
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: '',
  props: {
    inviteDialog: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {

    };
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    handleReject() {
      this.$emit('response-speak', 'reject');
      this.handleClose();
    },
    handleAgree: _.throttle(function () {
      this.$emit('response-speak', 'agreed');
      this.handleClose();
    }, 3000, {
      trailing: false,
    }),
  },
};

</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 26px 30px;
}

.body-wrapper {
  font-size: 12px;
  color: #666b71;
  line-height: 18px;
}

.invite-msg {
  font-size: 14px;
  color: #333b44;
  line-height: 24px;
  margin-bottom: 8px;
}
</style>
